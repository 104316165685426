// since unavailable on NPM - sourced from https://github.com/gtramontina/stripe-angular
// updated to match angular code style specs
(function () {
    'use strict';

    angular
        .module('stripe', [])
        .directive('stripeForm', stripeForm);

    stripeForm.$inject = ['$window'];

    function stripeForm ($window) {
        return {
            restrict: 'A',
            link: function (scope, element, attributes) {
                var form = angular.element(element);

                form.bind('submit', function () {
                    var button = form.find('button[type-submit]');

                    button.prop('disabled', true);
                    $window.Stripe.createToken(form[0], function () {
                        var args = arguments;

                        button.prop('disabled', false);
                        scope.$apply(function () {
                            scope.$eval(attributes.stripeForm).apply(scope, args);
                        });
                    });
                });
            }
        }
    }
})();
