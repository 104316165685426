// Based on Jeremy Weir's StackOverflow response → https://stackoverflow.com/questions/28204606/dynamic-body-class-with-angular-ui-router
(function () {
    'use strict';

    angular
        .module('cssStateClasses', [])
        .directive('cssStateClasses', cssStateClasses);

    cssStateClasses.$inject = [
        '$log',
        '$transitions',
    ];

    function cssStateClasses ($log, $transitions) {
        // noinspection UnnecessaryLocalVariableJS
        const directive = {
            link: link,
            restrict: 'A',
            scope: {}
        };
        return directive;

        function link (scope, elem) {
            // $log.info('Running link func for cssStateClasses.directive');
            $transitions.onSuccess({}, function ($transition$) {
                const fromClassnames = $transition$.from().cssClassnames ||  null;
                const toClassnames = $transition$.to().cssClassnames || null;

                // don't do anything if they are the same
                if (fromClassnames !== toClassnames) {
                    if (fromClassnames) {
                        elem.removeClass(fromClassnames);
                    }

                    if (toClassnames) {
                        elem.addClass(toClassnames);
                    }
                }
            });
        }
    }
}());
